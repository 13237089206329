import { getJunctionName } from '../utils'

export const normalizeResponseUnits = (response, allJunctions, allSectors, i18n) => {
  const translateUnits = units => units === '' ? '' : i18n.t(`message.units.${units}`)

  response.total.materials.forEach((sector) => {
    if (sector.junction) {
      sector.junction.forEach((obj) => {
        obj.materials.forEach((material) => {
          material.materials.forEach((material) => {
            material.units = translateUnits(material.units)
          })
        })
      })
    }

    sector.system.forEach((part) => {
      if (part?.materials) {
        part.materials.forEach((material) => {
          material.units = translateUnits(material.units)
        })
      }
    })
  })

  response.total.sectorMaterials.forEach((m) => {
    m.forEach((item) => {
      item.units = translateUnits(item?.units)
    })
  })

  response.total.computation.map(item => {
    return (item.units = translateUnits(item.units))
  })
  response.sectors.map((sector, sectorIndex) => {
    sector.sector.system.map(system => {
      return system.materials.map(material => {
        return (material.units = translateUnits(material.units))
      })
    })
    if (sector.sector.junctions) {
      sector.sector.junctions = sector.sector.junctions.map(junction => {
        const stateSectorJunction = allSectors[sectorIndex].junctions?.find((item) => item.id === junction.id)
        const name = getJunctionName(stateSectorJunction, junction.name, allSectors[sectorIndex].junctions, allJunctions)
        const prependedStr = i18n.t('message.details.system.usedMaterials.table.junctionType')

        return {
          name: `${prependedStr} ${name}`,
          originalName: name,
          materials: junction.materials.map(material => {
            return {
              ...material,
              units: translateUnits(material.units)
            }
          })
        }
      })
    }
    // eslint-disable-next-line no-unused-expressions
    sector.junctions?.map(junction => {
      return junction.map(layer => {
        return layer.materials.map(material => {
          return (material.units = translateUnits(material.units))
        })
      })
    })
    // eslint-disable-next-line no-unused-expressions
    sector.junctionsMaterials?.map(junction => {
      return junction.map(layer => {
        return (layer.units = translateUnits(layer.units))
      })
    })

    return sector
  })
  return response
}
