import Vue from 'vue'
import Router from 'vue-router'
import { fallbackLocale } from '@/plugins/i18n'
import { sendStatistic, statisticLabels } from '@/utils/sendStatistic'

export function initRouter(plugin) {
  const i18n = plugin
  const withHomePage = i18n.locale === 'in'
  const isLangWithDifferentRegionAndLocaleCodes = (i18n.locale === 'in' && JSON.parse(localStorage.getItem('langs')).includes('in-en'))

  Vue.use(Router)

  const router = new Router({
    mode: 'history',
    routes: [
      {
        path: '/',
        redirect: `/${i18n?.locale}`
      },
      {
        path: '/:lang',
        component: {
          render(h) {
            return h('router-view')
          }
        },
        children: [
          {
            path: '/',
            name: 'Home',
            component: () => import('@/views/Home.vue'),
            beforeEnter: (to, from, next) => {
              withHomePage ? next() : next({ path: `/systems/0` })
            }
          },
          {
            path: 'systems/:id',
            name: 'Systems',
            component: () => import('@/views/Systems.vue'),
            beforeEnter: (to, from, next) => {
              sendStatistic(statisticLabels.steps.systems)
              next()
            }
          },
          {
            path: 'wedge/:id',
            name: 'Wedge',
            component: () => import('@/views/Wedge.vue'),
            beforeEnter: (to, from, next) => {
              sendStatistic(statisticLabels.steps.wedge)
              next()
            }
          },
          {
            path: 'system/:id',
            name: 'System',
            component: () => import('@/views/Single-system.vue'),
            beforeEnter: (to, from, next) => {
              sendStatistic(statisticLabels.steps.system)
              next()
            }
          },
          {
            path: 'system/:id/junction/:number',
            name: 'Junction',
            component: () => import('@/views/Single-junction.vue'),
            beforeEnter: (to, from, next) => {
              sendStatistic(statisticLabels.steps.junction)
              next()
            }
          },
          {
            path: 'junctions/:id',
            name: 'Junctions',
            component: () => import('@/views/Junctions.vue'),
            beforeEnter: (to, from, next) => {
              sendStatistic(statisticLabels.steps.junctions)
              next()
            }
          },
          {
            path: 'result',
            name: 'Result',
            component: () => import('@/views/Result.vue'),
            beforeEnter: (to, from, next) => {
              sendStatistic(statisticLabels.steps.result)
              next()
            }
          },
          {
            path: 'details/:id',
            name: 'Details',
            component: () => import('@/views/Details.vue')
          },
          {
            path: 'details/:id/junction/:number',
            name: 'DetailsJunction',
            component: () => import('@/views/Details-junction.vue')
          },
          {
            path: '404',
            name: '404',
            component: () => import('@/views/404.vue')
          },
          {
            path: '*',
            name: 'MissingPage',
            component: () => import('@/views/404.vue')
          }
        ]
      }
    ],
    scrollBehavior() {
      return { x: 0, y: 0 }
    }
  })
  router.beforeEach((to, from, next) => {
    const isLanguageSupport = JSON.parse(localStorage.getItem('langs'))
      .split(',')
      .includes(to.params.lang) || isLangWithDifferentRegionAndLocaleCodes

    if (!isLanguageSupport) {
      next({ path: `/${fallbackLocale}${to.fullPath}` })
    }

    !to.path.includes(`/${i18n.locale}`)
      ? next({ path: `/${i18n.locale}${to.path}` })
      : next()
    window.parent.postMessage({ scrollTop: true }, '*')
  })

  return Promise.resolve({
    i18n: plugin,
    router
  })
}
