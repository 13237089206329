<template>
  <Modal :isModalOpen="isModalOpen" :isShowClose="true" @close="close">
    <template #body>
      <div class="leave-modal">
        <div class="leave-modal__title">{{ $t('message.modal.attentionTitle') }}</div>
        <div class="leave-modal__text">
          {{ modalText }}
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/elements/Modals/Modal'
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      default: () => null
    }
  },
  name: 'LeaveStepModal',
  components: { Modal },
  methods: {
    close() {
      this.$emit('closeModal')
    }
  },
  computed: {
    modalText() {
      return this.text || this.$t('message.modal.leaveModalText')
    }
  }
}
</script>

<style scoped lang="sass">
.leave-modal
  max-width: rem(400)
  padding: rem(25)
  box-sizing: border-box
  &__title
    color: $red
    font-size: rem(24)
    font-weight: bold
    margin-bottom: rem(12)
  &__text
    color: $black
    font-size: rem(14)
</style>
