import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getContent } from '@/api'

export const fallbackLocale = 'ru'

export function initI18n() {
  const locale = window.location.pathname.split('/').filter(i => i !== '')[0] || fallbackLocale

  return getContent(locale).then((data) => {
    localStorage.setItem('langs', JSON.stringify(Object.keys(data.data.languages).join(',')))
    localStorage.setItem('languages', JSON.stringify(data.data.languages))
    localStorage.setItem('disclaimer', JSON.stringify(data.data.disclaimer))

    Vue.use(VueI18n)

    return new VueI18n({
      locale,
      fallbackLocale,
      messages: {
        [locale]: data.data.locales
      }
    })
  })
}
