import WebSocketAsPromised from 'websocket-as-promised'

export default class WebSocketService {
  socket = null;
  url = null;
  socketId = null;
  windowListener = null;

  constructor(socketId) {
    const socketURL = process.env.VUE_APP_CAD_SOCKET_URL
    this.url = `${socketURL}/${socketId}`
    this.socketId = socketId
    this.socket = new WebSocketAsPromised(this.url, {
      packMessage: data => JSON.stringify(data),
      unpackMessage: data => JSON.parse(data),
      attachRequestId: (data, requestId) => ({ ...data, requestId }),
      extractRequestId: data => data && data.requestId
    })
  }

  connect() {
    return this.socket.open()
      .then(() => {
        // console.log('WebSocket connected')
      })
      .catch(() => {
        // console.error('WebSocket connection error: ', error)
      })
  }

  sendMessage(message) {
    return this.socket.sendPacked(message)
  }

  close() {
    return this.socket.close()
      .then(() => {
        // console.log('WebSocket closed')
      }).catch(() => {
        // console.error('WebSocket closed error: ', error)
      })
  }

  onClose(callback) {
    this.socket.onClose.addListener(callback)
  }

  onMessage(callback) {
    this.socket.removeAllListeners()
    this.socket.onUnpackedMessage.addListener(callback)
  }

  setWindowListener(listener) {
    this.windowListener = listener
  }

  closeWindowListener() {
    window.removeEventListener('message', this.windowListener)
    this.windowListener = null
  }
}
