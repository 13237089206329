<template>
  <nav class="nav" v-if="isNavShow" ref="Nav">
    <div class="container">
      <swiper :options="swiperOption" ref="mySwiper">
        <div
          @click="goToSystem(i)"
          class="swiper-slide"
          v-for="(sector, i) in sectors"
          :class="{ 'current-sector': i === parseInt(currentSectorIndex) }"
          :key="i"
        >
          <!--:class="{'disabled' : sectorFromState.materialSize.width === 0}"-->
          <div class="slide">
            <div class="slide__img">
              <span class="slide__number"> <span v-if="i + 1 < 10">0</span>{{ i + 1 }} </span>
              <img src="@/assets/img/nav/slide-tn.svg" alt="" v-if="!sector.isCustom" />
              <img src="@/assets/img/nav/slide-custom.svg" alt="" v-else />
            </div>
            <div class="slide__info">
              <div class="slide__info-square">
                <img src="@/assets/img/nav/slide-square.svg" class="slide__info-img" alt="" />
                {{ format(sector.square) }} {{ $t('message.units.m2') }}
              </div>
              <div class="slide__info-junctions">
                <img src="@/assets/img/nav/slide-junctions.svg" class="slide__info-img" alt="" />
                {{ sector.junctions.length }}
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <div
      class="nav__panes"
      :class="{
        active: currentStep === 'Systems',
        passed: findStepNumber(currentStep) > findStepNumber('Systems')
      }"
    >
      <div class="container">
        <div class="nav__panes-steps" :class="{ 'nav__panes-steps--fullPath': isWedgeExist }">
          <div
            class="step"
            v-for="step in filteredSteps"
            @click="clickOnStep(step)"
            :key="step"
            :class="getClasses(step)"
          >
            <div class="step__icon">
              <inline-svg :src="require(`@/assets/img/nav/step-${step}.svg`)" />
            </div>
            <div class="step__text">
              <i18n :path="`message.nav.steps.step${step}`">
                <template #break>
                  <br />
                </template>
              </i18n>
            </div>
          </div>
          <button @click="goToResult" class="btn" :disabled="isBtnResultDisabled">
            <inline-svg :src="require('@/assets/img/nav/result.svg')" />
            <span class="btn__text">
              {{ $t('message.nav.calculation') }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="nav__panes-mobile">
      <div class="container">
        <div class="head-pane">
          <div
            class="current__step"
            :class="{ 'current__step--active': isShowMobileNav }"
            @click="isShowMobileNav = !isShowMobileNav"
          >
            <div class="step__icon">
              <inline-svg :src="require(`@/assets/img/nav/step-${currentStep}.svg`)" />
            </div>
            <div class="step__text">
              <i18n :path="`message.nav.steps.step${currentStep}`"></i18n>
            </div>
            <div class="number">
              <div class="number__text">
                {{ findStepNumber(currentStep) }} / {{ filteredSteps.length }}
              </div>
              <div class="number__chevron">
                <img src="@/assets/img/icons/filter--select.svg" alt="↓" />
              </div>
            </div>
          </div>
          <button @click="goToResult" class="btn" :disabled="isBtnResultDisabled">
            <inline-svg :src="require('@/assets/img/nav/result.svg')" />
          </button>
        </div>
        <div class="nav__panes-mobile--steps" v-if="isShowMobileNav">
          <div
            class="step"
            v-for="step in filteredStepsMobile"
            @click="clickOnStep(step)"
            :key="step"
            :class="getClasses(step)"
          >
            <div class="step__icon">
              <inline-svg :src="require(`@/assets/img/nav/step-${step}.svg`)" />
            </div>
            <div class="step__text">
              <i18n :path="`message.nav.steps.step${step}`"></i18n>
            </div>
          </div>
        </div>
      </div>
    </div>
    <leave-step-modal :is-modal-open="isLeaveStepModalOpen" @closeModal="closeLeaveStepModal" />
  </nav>
</template>

<script>
import { isAllJunctionsValid } from '@/middlewares'
import {
  formatBigNumber,
  sumElements,
  isSomeWedgeIsEnabled,
  isWedgeExist,
  isTargetLayerInWedgeExist,
  isSomeWedgeLayerHasMaterial,
  isSectorHasSlopes,
  isWedgeSquareExistAndValid,
  isWedgeGluValid
} from '@/utils/customFunctions'
import { mapState } from 'vuex'
import InlineSvg from 'vue-inline-svg'
import LeaveStepModal from '@/components/elements/Modals/LeaveStepModal'
export default {
  data: () => ({
    isLeaveStepModalOpen: false,
    defaultSteps: ['Systems', 'System', 'Wedge', 'Junctions', 'Junction'],
    renderInSteps: ['Systems', 'System', 'Wedge', 'Junctions', 'Junction'],
    isShowMobileNav: false,
    swiperOption: {
      slidesPerView: 5.33,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false
      },
      breakpoints: {
        960: {
          slidesPerView: 5.33
        },
        768: {
          slidesPerView: 4.5
        },
        700: {
          slidesPerView: 3.5
        },
        520: {
          slidesPerView: 2.5
        },
        400: {
          slidesPerView: 2.1
        }
      }
    }
  }),
  components: {
    LeaveStepModal,
    InlineSvg
  },
  methods: {
    closeLeaveStepModal() {
      this.isLeaveStepModalOpen = false
    },
    goToResult() {
      if (this.isAllJunctionsValid) {
        this.$router.push(`/${this.$i18n.locale}/result`)
      } else {
        this.isLeaveStepModalOpen = true
      }
    },
    goToLastSlide() {
      this.swiper.slideTo(this.sectors.length, 1000)
    },
    goToSystem(index) {
      if (!this.isStepFourDisabled && parseInt(this.sectorIndex) !== index) {
        this.$router.push(`/${this.$i18n.locale}/system/${index}`)
      }
    },
    format(n) {
      return formatBigNumber(n)
    },
    sum(arr) {
      return formatBigNumber(sumElements(arr, 'count'))
    },
    getClasses(stepName) {
      if (stepName === 'Wedge' && !this.isKlinInWedgeExist) {
        return 'step--disable'
      } else {
        return [
          this.currentStep === stepName ? 'step--active' : '',
          this.findStepNumber(this.currentStep) > this.findStepNumber(stepName)
            ? 'step--passed'
            : ''
        ]
      }
    },
    clickOnStep(stepName) {
      if (this.currentStep !== stepName) {
        this.goToStep(stepName)
      }
    },
    goToStep(stepName) {
      if (stepName === 'Systems') {
        this.$router.push(`/${this.$i18n.locale}/systems/${this.sectorIndex}`)
        this.isShowMobileNav = false
      }
      if (stepName === 'System') {
        if (!this.isStepTwoDisabled) {
          this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
          this.isShowMobileNav = false
        }
      }
      if (stepName === 'Wedge') {
        if (!this.isStepThreeDisabled) {
          this.$router.push(`/${this.$i18n.locale}/wedge/${this.sectorIndex}`)
          this.isShowMobileNav = false
        }
      }
      if (stepName === 'Junctions') {
        if (!this.isStepFourDisabled) {
          this.$router.push(`/${this.$i18n.locale}/junctions/${this.sectorIndex}`)
          this.isShowMobileNav = false
        }
      }
      if (stepName === 'Junction') {
        if (!this.isStepFiveDisabled) {
          this.$router.push(
            `/${this.$i18n.locale}/system/${this.sectorIndex}/junction/${
              this.sectorFromState.junctions.length - 1
            }`
          )
          this.isShowMobileNav = false
        }
      }
    },
    findStepNumber(stepName) {
      return this.filteredSteps.findIndex(p => p === stepName) + 1
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors,
      allSystems: state => state.systems
    }),
    filteredSteps() {
      return isWedgeExist(this.sectorFromState?.layers)
        ? this.defaultSteps
        : this.defaultSteps.filter(p => p !== 'Wedge')
    },
    filteredStepsMobile() {
      return this.filteredSteps.filter(p => p !== this.currentStep)
    },
    currentStep() {
      return this.$route.name
    },

    currentSectorIndex() {
      return this.$route.params.id
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    isNavShow() {
      return this.renderInSteps.includes(this.currentStep) && this.allSystems.length
    },
    isBtnResultDisabled() {
      return this.isStepFourDisabled
    },
    isSquareInvalid() {
      return this.sectorFromState.square <= 0
    },
    isStepTwoDisabled() {
      return this.sectorFromState.layers.length === 0
    },
    isStepThreeDisabled() {
      return this.isStepTwoDisabled || this.isSquareInvalid || !this.isKlinInWedgeExist
    },
    isStepFourDisabled() {
      return (
        this.isStepTwoDisabled ||
        this.isSquareInvalid ||
        (this.isKlinInWedgeExist &&
          (!this.isSectorHasSlopes ||
            !this.isSomeWedgeLayerHasMaterial ||
            !this.isWedgeSquareExistAndValid ||
            !this.isWedgeGluValid))
      )
    },
    isStepFiveDisabled() {
      return this.isStepFourDisabled || this.sectorFromState.junctions.length <= 0
    },
    isWedgeExist() {
      return isWedgeExist(this.sectorFromState.layers)
    },
    isSomeWedgeIsEnabled() {
      return isSomeWedgeIsEnabled(this.sectorFromState.layers)
    },
    isKlinInWedgeExist() {
      return isTargetLayerInWedgeExist(this.sectorFromState.layers, 'wedge')
    },
    isSectorHasSlopes() {
      return isSectorHasSlopes(this.sectorFromState)
    },
    isSomeWedgeLayerHasMaterial() {
      return isSomeWedgeLayerHasMaterial([
        this.sectorFromState.wedge?.slopes?.mainSlope,
        this.sectorFromState.wedge?.slopes?.contrSlope
      ])
    },
    isWedgeSquareExistAndValid() {
      return isWedgeSquareExistAndValid(this.sectorFromState.wedge)
    },
    isWedgeGluValid() {
      return isWedgeGluValid(this.sectorFromState.wedge, this.sectorFromState.layers, 'glue')
    },
    isAllJunctionsValid() {
      return isAllJunctionsValid(this.sectors)
    }
  }
}
</script>

<style lang="sass">
nav
  position: relative
  .swiper
    &-container
      width: auto
      +media((margin: (320: 0, 1170: 0 auto)))
      .swiper-slide
        &.disabled
          pointer-events: none
          opacity: .5
        &.router-link-active
          opacity: 1
      &:hover
        .swiper
          &-scrollbar
            &-drag
              background: rgba(227, 7, 19, 0.5)
        > .swiper-scrollbar
          background: rgba(0, 0, 0, 0.1)
    &-slide
      text-decoration: none
      background: $default
      &.router-link-active, &.current-sector
        background: #fff
        &:before
          content: ""
          position: absolute
          height: rem(4)
          width: 100%
          pointer-events: none
          background: $red
          top: 0
          left: 0
        .slide__info-square
          color: $light-black
    &-scrollbar
      height: rem(4)
      top: 0
      &-drag
        border-radius: rem(12)
        background: rgba(227, 7, 19, 0.2)
  .swiper-container-horizontal > .swiper-scrollbar
    background: rgba(0, 0, 0, 0.05)
    height: rem(4)
    width: 100%
    left: 0
  .slide
    box-shadow: inset rem(-1) 0 0 #ececec
    display: flex
    flex-direction: row
    align-items: center
    &__img
      width: rem(80)
      height: rem(80)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      .slide
        &__number
          font-style: normal
          font-weight: bold
          font-size: rem(12)
          line-height: 150%
          color: $black
          position: absolute
          top: rem(4)
          left: rem(8)
    &__info
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: 100%
      color: $dark-grey
      &-img
        margin-right: rem(9)
      &-square, &-junctions
        display: flex
        flex-direction: row
        align-items: center
      &-square
        margin-bottom: rem(10)
.nav
  &__panes
    background: #FFFFFF
    box-shadow: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5)
    position: relative
    +media((display: (320: none, 960: block)))
    &:after
      content: ""
      transition: .5s
      height: rem(4)
      width: calc((100% - 960px) / 2)
      position: absolute
      left: 0
      bottom: 0
      background: $default
      +media((display: (320: none, 960: block)))
    &.active
      &:after
        background: #EA454E
    &.passed
      &:after
        background: #94E3B5
    &-steps
      display: grid
      grid-template-columns: repeat(4, 1fr)
      position: relative
      +media((width: (320: 100%, 960: calc(960px - 56px), 1080: rem(960), 1200: rem(960))))
      &--fullPath
        grid-template-columns: repeat(5, 1fr)
      .step
        box-shadow: inset rem(-1) 0 0 #ECECEC
        display: grid
        grid-template-columns: rem(60) 1fr
        padding: rem(4) 0
        position: relative
        cursor: pointer
        &:after
          content: ""
          transition: .5s
          height: rem(4)
          width: 100%
          position: absolute
          left: 0
          bottom: 0
          background: $default
        &--active
          &:after
            background: #EA454E
          .step
            &__icon
              svg
                path
                  fill: #EA454E
        &--passed
          &:after
            background: #94E3B5
          .step
            &__icon
              svg
                path
                  fill: #94E3B5
            &__text
              color: $dark-grey
        &--disable
          .step
            &__icon
              opacity: .3
            &__text
              opacity: .3
        &__icon
          height: rem(56)
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          width: 100%
          transition: .5s
          svg
            max-width: 100%
            height: auto
            path
              transition: .5s
        &__text
          font-style: normal
          font-weight: 500
          padding-top: rem(7)
          font-size: rem(12)
          line-height: 150%
          color: $light-black
          transition: .5s
      .btn
        transition: .5s
        background: $red
        cursor: pointer
        position: absolute
        top: 0
        +media((right: (320: 0, 960: rem(-56), 1080: calc(((100vw - 960px) / 2) * -1), 1200: rem(-120) )))
        +media((width: (320: rem(56), 1080: calc((100vw - 960px) / 2), 1200: rem(120))))
        box-shadow: none
        outline: none
        border: none
        height: 100%
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        font-style: normal
        font-weight: bold
        font-size: rem(12)
        line-height: 150%
        color: #fff
        font-family: $main-font
        &__text
          padding-left: rem(15)
          +media((display: (320: none, 1200: block)))
        &:hover
          background: $red-hover
        &:disabled
          background: #D6D6D6
          cursor: no-drop
    &-mobile
      +media((display: (320: block, 960: none)))
      padding-left: rem(8)
      background: #fff
      box-shadow: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5)
      .head-pane
        display: grid
        grid-template-columns: 1fr rem(56)
        .btn
          @extend .clear-btn
          background: $red
          &:hover
            background: $red-hover
          &:disabled
            background: #D6D6D6
            cursor: no-drop
      .current__step
        background: #ffffff
        z-index: 3
        padding: rem(8) 0 rem(8) rem(8)
        min-height: rem(64)
        box-sizing: border-box
        display: flex
        flex-direction: row
        align-items: center
        &--active
          .number
            &__chevron
              transform: rotate(180deg)
        .step
          &__icon
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            +media((min-width: (320: rem(48), 400: rem(56))))
            margin-right: rem(8)
            svg
              path
                fill: #EA454E
          &__text
            @extend .fs-14
            +media((font-size: (320: rem(12), 400: rem(14))))
            font-weight: bold
            color: $light-black
        .number
          @extend .fs-14
          +media((font-size: (320: rem(12), 400: rem(14))))
          color: #999999
          margin-left: auto
          +media((width: (320: rem(48), 400: rem(56))))
          height: 100%
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          &__chevron
            transition: .5s
            transform-origin: center
      &--steps
        background: #FFFFFF
        box-shadow: $default-box-shadow
        display: grid
        grid-template-columns: 1fr
        position: absolute
        left: 0
        width: calc(100% - 56px)
        z-index: 2
        .step
          min-height: rem(64)
          padding: rem(8)
          display: flex
          flex-direction: row
          box-sizing: border-box
          align-items: center
          &:not(:last-child)
            box-shadow: inset 0 rem(-1) 0 #ECECEC
          &__icon
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            margin-right: rem(8)
            +media((min-width: (320: rem(48), 400: rem(56))))
          &--active
            .step
              &__icon
                svg
                  path
                    fill: #EA454E
          &--passed
            .step
              &__icon
                svg
                  path
                    fill: #94E3B5
              &__text
                color: $dark-grey
          &--disable
            .step
              &__icon
                opacity: .3
              &__text
                opacity: .3
          &__text
            @extend .fs-14
            +media((font-size: (320: rem(12), 400: rem(14))))
            font-weight: bold
            color: $light-black
</style>
