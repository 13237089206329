<template>
  <div id="app">
    <loading
        :active.sync="computedLoading"
        :can-cancel="false"
        color="#E30713"
        :height="145"
        :width="145"
        :opacity="1"
        backgroundColor="#eeeeee"
        :z-index="5"
        is-full-page
    ></loading>
    <Nav ref="Nav" />
    <router-view />
    <notifications group="layerError" position="top right" />
    <notifications group="foo" />
  </div>
</template>

<script>
import { setVh } from '@/utils/setVh'
import Nav from '@/components/smart/Nav'
import { mapActions, mapState } from 'vuex'
import { uuid } from 'vue-uuid'
const routesWithoutLoader = ['404', 'MissingPage']

export default {
  name: 'app',
  provide() {
    return {
      slideToLast: this.slideToLast
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.meta.title')
    }
  },
  components: {
    Nav
  },
  methods: {
    ...mapActions({
      addSessionId: 'addSessionId',
      addSocketId: 'addSocketId',
      addObjectId: 'addObjectId',
      markCalculationAsSaved: 'markCalculationAsSaved',
      addPublicLink: 'addPublicLink'
    }),
    slideToLast() {
      this.$refs.Nav.goToLastSlide()
    }
  },
  mounted() {
    setVh()
    this.addSocketId(uuid.v1())
    window.parent.postMessage({ auth: null }, '*')
    window.addEventListener('message', (evt) => {
      const hasAuthField = Object.keys(evt.data)?.some((field) => field === 'auth')
      const hasObjIdField = Object.keys(evt.data)?.some((field) => field === 'objId')
      const isSavingSuccessful = Object.keys(evt.data)?.some((field) => field === 'saved')

      if (hasAuthField) {
        this.addSessionId(evt.data.auth)
      }

      if (hasObjIdField) {
        this.addObjectId(evt.data.objId)
      }

      if (isSavingSuccessful) {
        this.markCalculationAsSaved(evt.data.saved)
        this.addPublicLink(evt.data.saved)
      }
    })
  },
  computed: {
    ...mapState({
      isLoading: state => state.pages.systems.isLoading
    }),
    computedLoading() {
      return routesWithoutLoader.includes(this.$route.name) ? false : this.isLoading
    }
  }
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext,latin-ext')

#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

body
  font-family: 'Montserrat', sans-serif
  margin: 0
  background: $ec
  overflow-x: hidden
  &.bg--white
    background: $white

  #app
    text-align: left

  &.modal-open
    overflow-y: hidden

  .container
    max-width: rem(960)
    +media((width: (0: 100%, 960: calc(100% - 32px), 992: rem(960))))
    margin: auto
    position: relative

html
  scroll-behavior: smooth
  -moz-scroll-behavior: smooth

h2
  margin: 0

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type='number']
  -moz-appearance: textfield
</style>
