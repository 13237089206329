import { cloneDeep, isEmpty } from 'lodash'
import { isLayerHasFastener, getItemNumber } from '@/utils/customFunctions'

const shift = 60
const offset = 6
const offsetFull = 60
const minLayer = 5
const rhombusHeight = 192
export const scalingFactor = 0.65
export function getFilteredLayers(layers, categories) {
  const clonedLayers = cloneDeep(layers)
  for (let i = 0; i < clonedLayers.length; i += 1) {
    clonedLayers[i].realIndex = i
  }
  const filteredLayers = clonedLayers.filter(
    l =>
      !Object.prototype.hasOwnProperty.call(l, 'isEnabled') ||
      (Object.prototype.hasOwnProperty.call(l, 'isEnabled') && l.isEnabled)
  )

  filteredLayers.forEach(p => {
    p.items = p.items.filter(l => !isEmpty(l.material))
  })
  filteredLayers.forEach(p => {
    p.items.forEach(l => {
      isImageLayer(l, categories)
    })
  })
  return filteredLayers
}

export function isImageLayer(item, categories) {
  const currentCategory = categories.find(p => p.id === item.material.category)
  if (Object.prototype.hasOwnProperty.call(currentCategory, 'schemeImage')) {
    item.material.value = currentCategory.schemeImage.height
    item.isImageLayer = true
  }
  return item
}
const calculateScaledHeightOfLayer = layer => {
  const isImageLayer = layer.isImageLayer
  let computedHeight
  if (isImageLayer) {
    computedHeight =
      layer.material.value > 0 && layer.material.value < minLayer ? minLayer : layer.material.value
  } else {
    computedHeight =
      layer.material.value > 0 && layer.material.value < minLayer
        ? minLayer * scalingFactor
        : layer.material.value * scalingFactor // для слоев меньше 5 мм
  }
  return computedHeight
}

export function getLayersTotalHeight(layers, isInFullScreen) {
  const innerOffset = isInFullScreen ? offsetFull : offset
  const totalHeight = []
  layers.forEach(p => {
    if (p.expanded) {
      totalHeight.push(p.items.length * shift, shift)
    }
    p.items.forEach(l => {
      const computedHeight = calculateScaledHeightOfLayer(l)
      totalHeight.push(computedHeight, innerOffset)
    })
  })
  return sumArr(totalHeight) + rhombusHeight
}

export function getDelta(layers, layerIndex, itemIndex, coefficient, isInFullScreen) {
  const delta = []
  const clonedLayers = cloneDeep(layers)
  const innerOffset = isInFullScreen ? offsetFull : offset
  clonedLayers[layerIndex].items = clonedLayers[layerIndex].items.slice(itemIndex)
  const filteredLayers = clonedLayers.slice(layerIndex)
  filteredLayers.forEach(p => {
    if (p.expanded) {
      delta.push(p.items.length * shift, shift)
    }
    p.items.forEach(l => {
      const computedHeight = calculateScaledHeightOfLayer(l)
      delta.push(computedHeight, innerOffset)
    }) // для слоев меньше 5 мм
  })
  return (sumArr(delta) + rhombusHeight) * coefficient
}

export function getY(
  layers,
  layerIndex,
  itemIndex,
  height,
  coefficient,
  isInFullScreen,
  isImageLayer = false
) {
  const returnedVal = getDelta(layers, layerIndex, itemIndex, coefficient, isInFullScreen)
  let computedHeight
  if (isImageLayer) {
    computedHeight = height > 0 && height < minLayer ? minLayer * coefficient : height * coefficient
  } else {
    computedHeight =
      height > 0 && height < minLayer
        ? minLayer * scalingFactor * coefficient
        : height * scalingFactor * coefficient // для слоев меньше 10 мм
  }
  // console.log(computedHeight)
  const y1 = returnedVal - (layers[layerIndex].expanded ? shift * coefficient : 0)

  const y2 = y1 - 96 * coefficient
  const y3 = y2 - computedHeight
  const y4 = y3 - 96 * coefficient
  const circuitY = y1 - computedHeight
  return { y1, y2, y3, y4, circuitY }
}

export function getPath(...args) {
  // const args = [layers, layerIndex, itemIndex, height, coefficient]
  const hexagon = `M168 ${getY(...args).y1}
                   L336 ${getY(...args).y2}
                   L336 ${getY(...args).y3}
                   L168 ${getY(...args).y4}
                   L0 ${getY(...args).y3}
                   L0 ${getY(...args).y2}Z`
  const hexagonTop = `M168 ${getY(...args).y4}
                      L336 ${getY(...args).y3}
                      L336 ${getY(...args).y3 + 3}
                      L168 ${getY(...args).circuitY + 3}
                      L0 ${getY(...args).y3 + 3}
                      L0 ${getY(...args).y3}Z`
  const circuit = `M168 ${getY(...args).y1}
                   L168 ${getY(...args).circuitY}
                   L336 ${getY(...args).y3}
                   L168 ${getY(...args).y4}
                   L0 ${getY(...args).y3}
                   L168 ${getY(...args).circuitY}
                   M0 ${getY(...args).y3}
                   L0 ${getY(...args).y2}
                   M336 ${getY(...args).y3}
                   L336 ${getY(...args).y2}`
  const polygonTop = `M168 ${getY(...args).y4}
                      L336 ${getY(...args).y3}
                      L168 ${getY(...args).circuitY}
                      L0 ${getY(...args).y3}Z`
  const polygonLeft = `M168 ${getY(...args).y1}
                       L168 ${getY(...args).circuitY}
                       L0 ${getY(...args).y3}
                       L0 ${getY(...args).y2}Z`
  const polygonRight = `M168 ${getY(...args).y1}
                        L168 ${getY(...args).circuitY}
                        L336 ${getY(...args).y3}
                        L336 ${getY(...args).y2}Z`
  const textY = (getY(...args).y3 + getY(...args).y2) / 2
  // const imageLayer = { y1: getY(...args).y1, y4: getY(...args).y4, circuitY: getY(...args).circuitY }
  return { hexagon, hexagonTop, circuit, polygonTop, polygonLeft, polygonRight, textY }
}

export function getFastenerPath(
  layers,
  layerIndex,
  itemIndex,
  height,
  coefficient,
  fastenerHeight,
  collectedLayers,
  fastenersOnScheme,
  layerNumber,
  isInFullScreen
) {
  const args = [layers, layerIndex, itemIndex, height, coefficient, isInFullScreen]
  // console.log(fastenersOnSheme, layerNumber)
  const innerOffset = isInFullScreen ? offsetFull : offset
  const fastenerPosition = fastenersOnScheme.findIndex(p => p === layerNumber) + 1
  const layerParts = 168 / (fastenersOnScheme.length + 1)
  const fastenerWidth = 5
  const x = layerParts * fastenerPosition
  const x1 = x - fastenerWidth / 2
  const x2 = x1 + fastenerWidth
  const x3 = x1 + fastenerWidth / 2
  // const isExpaned = layers[layerIndex].expanded
  const optimizedHeight = (fastenerHeight + collectedLayers * innerOffset) * coefficient

  // const expanedHeight = ((layers[layerIndex].items.length * shift) * coefficient) + optimizedHeight
  // if (isExpaned) {
  //   console.log(expanedHeight)
  // }
  const fastenerShift =
    ((getY(...args).circuitY - getY(...args).y3) / (fastenersOnScheme.length + 1)) *
    fastenerPosition
  const y = getY(...args).y3 + fastenerShift
  const fastenerBody = `M${x1} ${y}
                         L${x2} ${y}
                         L${x2} ${y + optimizedHeight}
                         L${x3} ${y + optimizedHeight + 10} 
                         L${x1} ${y + optimizedHeight}Z`
  const ellipse = { x: x, y: y }
  const ellipseMini = { x: x, y: y }
  return { fastenerBody, ellipse, ellipseMini }
}

export function getTotalFastenersCount(layers) {
  const totalCount = []
  for (let i = 0; i < layers.length; i += 1) {
    for (let n = 0; n < layers[i].items.length; n += 1) {
      if (isLayerHasFastener(layers[i].items[n])) {
        totalCount.push(getItemNumber(i, n, layers))
      }
    }
  }
  return totalCount
}

export function scrollToLayer(target) {
  window.scrollTo({
    top: target - 100,
    behavior: 'smooth'
  })
}

export function highlightLayer(layer, index) {
  layer.setAttribute('tabindex', index)
  layer.focus()
}

export const sumArr = arr => (arr.length > 0 ? arr.reduce((acc, val) => acc + val, 0) : 0)
