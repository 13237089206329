import Vue from 'vue'
import { initI18n } from '@/plugins/i18n'
import VueTippy, { TippyComponent } from 'vue-tippy'
import MetaInfo from 'vue-meta-info'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import VueLoading from 'vue-loading-overlay'
import UnsupportBrowsers from '@/UnsupportBrowsers'
import App from '@/App'
import { initRouter } from '@/router'
import { initStore } from '@/store'

import 'swiper/dist/css/swiper.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'tippy.js/themes/light.css'
import VModal from 'vue-js-modal'

Vue.use(MetaInfo)
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)
Vue.use(VueLoading)
Vue.component('loading', VueLoading)
Vue.use(VueAwesomeSwiper)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VModal)
Vue.config.productionTip = false

const $body = document.querySelector('body')
let scrollPosition = 0
// Internet Explorer 6-11
const isIE = !!document.documentMode
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia

initI18n().then(
  (i18n) => initRouter(i18n).then(
    (i18nAndRouter) => initStore(i18nAndRouter).then(
      (threeModules) => {
        const { i18n, router, store } = threeModules

        if (!isIE && !isEdge) {
          const vm = new Vue({
            i18n,
            router,
            store,
            render: h => h(App),
            methods: {
              onBodyScroll(isNeedReturn = true) {
                this.enabledScroll(isNeedReturn)
              },
              offBodyScroll(needScroll = true) {
                this.disableScroll(needScroll)
                window.parent.postMessage({ scrollTop: true }, '*')
              },
              disableScroll(needScroll) {
                scrollPosition = window.pageYOffset

                $body.style.overflow = 'hidden'
                if (needScroll) {
                  $body.style.position = 'fixed'
                }
                $body.style.width = '100%'
              },
              enabledScroll(isNeedReturn) {
                $body.style.removeProperty('overflow')
                $body.style.removeProperty('position')
                $body.style.removeProperty('width')
                if (isNeedReturn) {
                  window.scrollTo(0, scrollPosition)
                }
              }
            },
            mounted() {
              const observer = new ResizeObserver(entries => {
                entries.forEach(entry => {
                  window.parent.postMessage({ height: entry.contentRect.height }, '*')
                })
              })
              observer.observe(document.body)
              window.addEventListener('beforeunload', () => {
                observer.disconnect()
              })
            }

          }).$mount('#app')
          Vue.directive('click-outside', {
            bind(el, binding) {
              el.addEventListener('click', e => e.stopPropagation())
              document.body.addEventListener('click', binding.value)
            },
            unbind(el, binding) {
              document.body.removeEventListener('click', binding.value)
            }
          })

          return vm
        } else {
          return new Vue({
            i18n,
            router,
            store,
            render: h => h(UnsupportBrowsers),
            methods: {},
            mounted() {}
          }).$mount('#app')
        }
      }
    )
  )
)
