export const sendStatistic = label => window.dataLayer.push({ 'event': label })

export const calcLabel = 'flat-roof'

export const statisticLabels = {
  prefooter: {
    pdf: `${calcLabel}-pdf`,
    xls: `${calcLabel}-xls`,
    print: `${calcLabel}-print`,
    save: `${calcLabel}-save-calculation`
  },
  steps: {
    systems: `${calcLabel}-step-1`,
    system: `${calcLabel}-step-2`,
    wedge: `${calcLabel}-step-3`,
    junctions: `${calcLabel}-step-4`,
    junction: `${calcLabel}-step-5`,
    result: `${calcLabel}-step-final`
  }
}
